<template>
  <Defaultlayout :userprofile="userprofile">
    <section class="order-status">
      <div class="container" v-if="loading==false">
        <div class="successfull-order-info text-center">
            <h2 class="text-black" v-if="order_no != null">{{order_no}}</h2>
            <div class="order-status-icon">
              <img src="../../assets/images/svgs/order-cancelled.svg" alt="order-cancelled">
            </div>
            <h2 class="text-black">Order Cancelled!</h2>
            <p class="mb-0 cl-gray gm-book" v-if="cancelled_order_detail.method_type_val != 2">The request for refund has been submitted. It may take 5-7 days to process.<br> Thank you for your patience.</p>
        </div>
        <div class="row justify-content-center btn-row">
          <a href="/" class="btn btn btn-outline-danger">Go to Home</a>
        </div>
      </div>
      <div class="container" v-if="loading==true">
        <p class="text-center">
          <img src="../../assets/images/loading.gif" />
        </p>
      </div>
    </section>
  </Defaultlayout>
</template>
<script>
import Defaultlayout from "../../layouts/Defaultlayout";
export default {
  name: "OrderCancelled",
  components: {
    Defaultlayout,
  },
  data() {
    return {
      loading: false,
      userprofile: null,
      order_no: null,
      order_id:null,
      orderdetail: {
        order_id: '',
      },
      cancelled_order_detail:null
    };
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
    this.order_no = localStorage.getItem("cancelled-order-no");
    this.order_id = localStorage.getItem("cancelled-order-id");

    this.loading = true;
    this.orderdetail.order_id = this.order_id;
    this.$store
      .dispatch("getcustomerorderdetail", this.orderdetail)
      .then(response => {
          if(response.status != 0)
          {
            this.cancelled_order_detail = response.data;
          }
          else 
          {
            this.$toast.error(response.message);
          }
          this.loading=false;
      });
  },
};
</script>